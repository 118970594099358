import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import BlogCard from '../../components/BlogElements/BlogCard';
import axios from 'axios'
import {Link,useParams} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'

const SideBar = (props) => {


    useEffect(()=>{
      console.log(props.item)
    },[])





    const filesList=props.files ? props.files.map(file=>{

        return(
            <a href="#" onClick={()=>props.setMainFileID(file.FileID)}>
            <img src={`${process.env.REACT_APP_API_SERVER}files/file/${file.FileID}`} width="30"/>
            </a>
        )
    }) : []

    const tagsList=props.item.Tags ? props.item.Tags.split('#').map(tag=>{
        if (tag.length>0){
            return(
                <>
                <Link onClick={()=>sessionStorage.setItem('search',tag)} to="/results" className="tag-cloud-link">{tag}</Link>
                &nbsp;
                </>
            )
        }

    }) : []


    const rdList=props.item.rd ? props.item.rd.map(item=>{
       
            if (item.ContentID!==props.item.ContentID){
                return(
                    <>
                    <Link to={`/item/${item.CNTNCRPTID}`} className="tag-cloud-link">{item.ContentLangTitle}</Link>
                    &nbsp;
                    </>
                )
            }



    }) : []


    return (
        <div className="col-lg-4 col-md-5 mt-5 mt-md-0">

            <div className="sidebar">



                <div className="widget widget_block widget_custom">
                    <h2 className="widget-title">Λεπτομέρειες</h2>
                    <div className="sidebar_author">
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td width="40%" bgColor="#f0f0f0"><b>Κωδικός</b></td>
                                    <td width="10"></td>
                                    <td>{props.item.ContentCode}</td>
                                </tr>
                                <tr><td colSpan="3" height={10}></td></tr>                     

                                <tr>
                                    <td width="40%" bgColor="#f0f0f0"><b>Ημ/νία Εγγράφου</b></td>
                                    <td width="10"></td>
                                    <td>{props.item.ContentDate} | {props.item.Calendar==1 ? 'ΝΕΟ ΗΜΕΡΟΛΟΓΙΟ' : 'ΠΑΛΑΙΟ ΗΜΕΡΟΛΟΓΙΟ'}</td>
                                </tr>
                                <tr><td colSpan="3" height={10}></td></tr>                                                       
                                <tr>
                                    <td width="40%" bgColor="#f0f0f0"><b>Περιοχή</b></td>
                                    <td width="10"></td>
                                    <td>{props.item.Area}</td>
                                </tr>
                                <tr><td colSpan="3" height={10}></td></tr>

                                <tr>
                                    <td width="40%" bgColor="#f0f0f0"><b>Αποστολέας/Παραλήπτης </b></td>
                                    <td width="10"></td>
                                    <td>{props.item.SenderReceiver}</td>
                                </tr>
                                <tr><td colSpan="3" height={10}></td></tr>

                                <tr>
                                    <td width="40%" bgColor="#f0f0f0"><b>Υπογράφων</b></td>
                                    <td width="10"></td>
                                    <td>{props.item.SignedBy}</td>
                                </tr>                                
                            </tbody>
                        </table>
                        {/* <hr/>
                        {filesList}
                        <hr/> */}
                    </div>
                </div>
                {/* <!-- end widget --> */}

                {/* <div className="widget widget_block">
                    <div className="wp-block-group__inner-container">
                        <h2 className="widget-title">Recent Posts</h2>
                        <ul className="wp-block-latest-posts__list wp-block-latest-posts">
                            <li>
                                <div className="latest-posts-image">
                                    <img src="images/blog/1.jpg" alt="img" />
                                </div>
                                <div className="latest-posts-content">
                                    <h5><a href="/blog">How consultation in business is affecting new ventures</a></h5>
                                    <h6>January 11, 2023</h6>
                                </div>
                            </li>
                            <li>
                                <div className="latest-posts-image">
                                    <img src="images/blog/2.jpg" alt="img" />
                                </div>
                                <div className="latest-posts-content">
                                    <h5><a href="/blog">Challenges of consultation new Business Firms</a></h5>
                                    <h6>January 01, 2023</h6>
                                </div>
                            </li>
                            <li>
                                <div className="latest-posts-image">
                                    <img src="images/blog/3.jpg" alt="img" />
                                </div>
                                <div className="latest-posts-content">
                                    <h5><a href="/blog">Changes in recent consultancy styles now a days</a></h5>
                                    <h6>January 21, 2023</h6>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div> */}
                {/* <!-- end widget --> */}

                {/* <div className="widget widget_block">
                    <div className="wp-block-group__inner-container">
                        <h2 className="widget-title">Categories</h2>
                        <ul className="wp-block-categories-list wp-block-categories">
                            <li className="cat-item"><a href="#">Consultaion</a> (10)</li>
                            <li className="cat-item"><a href="#">Business</a> (12)</li>
                            <li className="cat-item"><a href="#">Marketing</a> (08)</li>
                            <li className="cat-item"><a href="#">Finance</a> (15)</li>
                            <li className="cat-item"><a href="#">Campaign</a> (21)</li>
                        </ul>
                    </div>
                </div> */}
                {/* <!-- end widget --> */}

                <div className="widget widget_block widget_tag_cloud">
                    <div className="wp-block-group__inner-container">
                        <h2 className="widget-title">Tags</h2>
                        <ul className="wp-block-tag-list wp-block-tag">
                            <p className="wp-block-tag-cloud">
                                {/* <a href="#" className="tag-cloud-link">{props.item.Tags}</a>
                                <a href="#" className="tag-cloud-link">Finance</a>
                                <a href="#" className="tag-cloud-link">Marketing</a>
                                <a href="#" className="tag-cloud-link">Consultancy</a>
                                <a href="#" className="tag-cloud-link">Process</a>
                                <a href="#" className="tag-cloud-link">Meeting</a>
                                <a href="#" className="tag-cloud-link">Campaign</a> */}
                                {tagsList}
                            </p>
                        </ul>
                    </div>
                </div>

                <div className="widget widget_block widget_tag_cloud">
                    <div className="wp-block-group__inner-container">
                        <h2 className="widget-title">Σχετιζόμενα Έγγραφα</h2>
                        <ul className="wp-block-tag-list wp-block-tag">
                            <p className="wp-block-tag-cloud">
                                {/* <a href="#" className="tag-cloud-link">{props.item.Tags}</a>
                                <a href="#" className="tag-cloud-link">Finance</a>
                                <a href="#" className="tag-cloud-link">Marketing</a>
                                <a href="#" className="tag-cloud-link">Consultancy</a>
                                <a href="#" className="tag-cloud-link">Process</a>
                                <a href="#" className="tag-cloud-link">Meeting</a>
                                <a href="#" className="tag-cloud-link">Campaign</a> */}
                                {rdList}
                            </p>
                        </ul>
                    </div>
                </div>

                {/* <!-- end widget --> */}
            </div>
        </div>
    );
};

export default SideBar;