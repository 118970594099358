import React, { useState,useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import ConsultancyFooter from '../components/ConsultancyElements/ConsultancyFooter';
import ConsultancyHeader from '../components/ConsultancyElements/ConsultancyHeader';
import ScrollToTop from '../components/common/ScrollToTop/ScrollToTop';
import {UserContext} from '../../src/context/UserContext'

const ConsultancyLayout = () => {

    const [searchStr,setSearchStr]=useState('')

    useEffect(()=>{

    },[])
    return (
        <>
            <>
                {/* <div className="page-wrapper"> */}
                <UserContext.Provider value={{searchStr,setSearchStr}}>
                <ConsultancyHeader />
                <Outlet />
                <ConsultancyFooter />
                </UserContext.Provider>
                {/* </div> */}
            </>
            <ScrollToTop />
        </>
    );
};

export default ConsultancyLayout;