import React from 'react';

const About = () => {
    return (
        <section className="about-section" id="about">
            <div className="container">
                {/* <!-- Business Section --> */}
                <div className="inner-container">
                    <div className="row clearfix">
                        {/* <!-- Image Column --> */}
                        <div className="image-column col-lg-6 col-md-5 col-sm-12">
                            <div className="inner-column">
                                <div className="image">
                                    <img src="images/resource/about.jpg" alt="img" />
                                    {/* <div className="experience-counter">
                                        <div className="experience-counter-inner">
                                            2011111
                                            <p>Years Of Experience</p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <!-- Content Column --> */}
                        <div className="content-column col-lg-6 col-md-7 col-sm-12 px-lg-0">
                            <div className="inner-column">
                                {/* <!-- Title Box --> */}
                                <div className="title-box">
                                    <div className="title">ΣΧΕΤΙΚΑ ΜΕ ΤΟ ΕΡΓΟ</div>
                                    <h1>Ψηφιακό Αρχείο Ευξείνου Λέσχης</h1>
                                    <p>

                                    Οι σκοποί του Σωματείου καθορίζονται στο άρθρο 2 του καταστατικού του και περιστρέφονται γύρω από, αφενός, την πνευματική, πολιτιστική και κοινωνική ανάπτυξη των μελών του, καθώς και την καλλιέργεια κοινωνικών σχέσεων και την αλληλεγγύη μεταξύ τους, αφετέρου δε, την εξωστρεφή δραστηριότητα και συνεργασία με τον υπόλοιπο οργανωμένο ποντιακό χώρο, με στόχο την προώθηση των θεμάτων που τον απασχολούν.
                                    <br/><br/>
                                    Με την πατριωτική και ανθρωπιστική θέρμη που διέκρινε τους ιδρυτές της, από την ίδρυσή της μέχρι σήμερα, η Εύξεινος Λέσχη έχει επιτελέσει πλουσιότατο έργο, εθνικό, κοινωνικό, πνευματικό, πολιτιστικό και παραμένει μία πολύτιμη «κιβωτός», όπου διασώζονται ευλαβικά τα ήθη, τα έθιμα, η γλώσσα και τα υπόλοιπα στοιχεία του λαμπρού πολιτισμού που άνθισε στον Πόντο και μεταφέρθηκε στην Ελλάδα από τους Έλληνες πόντιους πρόσφυγες.
                                    <br/><br/>
                                    Στους κόλπους της οργανώθηκε η ανιστόρηση των περισσοτέρων Ιερών Μονών του ιστορικού Πόντου.
                                    <br/><br/>
                                    Στην Εύξεινο Λέσχη γεννήθηκε η ιδέα, που έγινε πράξη στη συνέχεια, για τη λειτουργία «Λαϊκού Πανεπιστημίου».
                                    <br/><br/>
                                    Στη διάρκεια της γερμανικής κατοχής, συνέδραμε την κοινωνία της Θεσσαλονίκης και των περιφερειακών συνοικισμών, περιθάλποντας εκατοντάδες παιδιά και οργανώνοντας διανομή τροφίμων και παροχή ιατρικής και φαρμακευτικής βοήθειας.
                                    <br/><br/>
                                    Το 1957, ίδρυσε τον «Οίκο Ακρίτα Φοιτητού», ένα φοιτητικό οικοτροφείο, όπου για πολλά χρόνια βρήκαν στέγη και φροντίδα εκατοντάδες άποροι φοιτητές.
                                    <br/><br/>
                                    Ανέπτυξε πολύ σημαντική καλλιτεχνική δραστηριότητα, με τα τμήματα ποντιακού θεάτρου και χορού.
                                    <br/><br/>
                                    Αναπροσαρμόζοντας τις δράσεις της, ανάλογα με τις συνθήκες που επικρατούν στην ελληνική κοινωνία, η Εύξεινος Λέσχη συνεχίζει να υπηρετεί με συνέπεια τους σκοπούς της ίδρυσής της.
                                    <br/><br/>
                                    Οργανώνει εκδηλώσεις, με στόχο τη διατήρηση και ενίσχυση των δεσμών μεταξύ των μελών της και την ανάδειξη της ιστορίας του ποντιακού ελληνισμού.
                                    <br/><br/>
                                    Υποστηρίζει με υποτροφίες πανεπιστημιακούς υποτρόφους και βραβεύει αριστούχους μαθητές και σπουδαστές.
                                    <br/><br/>
                                    Πρωτοστατεί στη διεκδίκηση της διεθνούς αναγνώρισης της Γενοκτονίας των Ελλήνων του Πόντου.
                                    <br/><br/>
                                    Για τη συνολική της δράση, η Εύξεινος Λέσχη Θεσσαλονίκη τιμήθηκε το 2003 από την Ακαδημία Αθηνών.

                                    </p>
                                </div>
                                {/* <!-- End Title Box --> */}

                                {/* <!-- Feature Block --> */}
                                {/* <div className="work-list d-md-flex align-items-center justify-content-between"> */}
                                    {/* <ul>
                                        <li><img src="images/icons/chevron-right.png" alt="img" /> Market Competitor Analysis</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" /> Research & Development</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" /> Marketing Decision Making</li>
                                    </ul>
                                    <ul>
                                        <li><img src="images/icons/chevron-right.png" alt="img" /> New Branding Strategies</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" /> Leadership Training program</li>
                                        <li><img src="images/icons/chevron-right.png" alt="img" /> Auditing & Taxation Service</li>
                                    </ul> */}
                                {/* </div> */}

                                <div className="author-block d-lg-flex align-items-center justify-content-between">
                                    <div className="author-profile d-flex align-items-center">
                                        {/* <div className="author-img">
                                            <img src="images/resource/author-3.png" alt="img" />
                                        </div>
                                        <div className="author-info">
                                            <h4>+0(412) 789-4432</h4>
                                            <p>Do you have any question?</p>
                                        </div> */}
                                    </div>

                                    <div className="author-signature">
                                        <img src="images/resource/signature.png" alt="img" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;