import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import BlogCard from '../../components/BlogElements/BlogCard';
import axios from 'axios'
import {Link,useParams} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'

const BlogGridContainer = () => {

    const [blogGridData,setBlogGridData ]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const {searchStr,setSearchStr}=useContext(UserContext)


    //const {catid}=useParams()

    useEffect(()=>{
        window.scrollTo(0, 0)
            loadData()

        
    },[searchStr]) 


    // useEffect(()=>{
    //     window.scrollTo(0, 0)
    //         loadData()

        
    // },[catid])   

    const loadData=()=>{
        setIsLoading(true)

        var url=process.env.REACT_APP_API_SERVER+'content'

        //console.log(url)
        axios.post(url,{s:sessionStorage.getItem('search')})
        .then(res=>{
            console.log(res.data.results) 
            setBlogGridData(res.data.results)
            setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        }) 
    }


    const blogGridDataList=blogGridData.map(item=>{
        var SearchField=''
        Object.keys(item).forEach(ok=>{
                
            if (ok.includes('_Found')){
                
                if (item[ok]==1){

                    if (ok.split('_Found').join('')=='Tags'){
                        SearchField='Tags'
                    }

                    if (ok.split('_Found').join('')=='ContentLangTitle'){
                        SearchField='Τίτλος'
                    }

                    if (ok.split('_Found').join('')=='ContentLangDescr'){
                        SearchField='Κείμενο'
                    }

                    if (ok.split('_Found').join('')=='Area'){
                        SearchField='Περιοχή'
                    }
                    
                    if (ok.split('_Found').join('')=='SignedBy'){
                        SearchField='Υπογράφων'
                    }                    

                    if (ok.split('_Found').join('')=='SenderReceiver'){
                        SearchField='Αποστολέας/Παραλήπτης'
                    }  
                } 
            }
        })

        const data={
                id: item.ContentID,
                img: `${process.env.REACT_APP_API_SERVER}files/file/${item.FileID}`,
                authorImg: 'images/blog/author-5.jpg',
                tag: 'Consultant',
                date: 'January 11, 2023',
                author: 'Jessica Ballinger',
                ContentTitle:item.ContentLangTitle,
                ContentDescr:item.ContentDescr,
                CNTNCRPTID:item.CNTNCRPTID,
                SearchField
            }


            console.log(data)

            return(
                <BlogCard
                key={data.id}
                data={data}
                />  
            )

    })

    return (
        <>
        {!isLoading && 
        <section className="news-section">
            
            <div className="container">
            <p ><h2>{sessionStorage.getItem('search')}</h2></p>
                <div className="row">
                    {blogGridDataList}
                </div>
            </div>

        </section>
        }

        {isLoading &&
        <div style={{height:'100vh'}}>
            <p align="center">
            <h2>Loading, please wait...</h2>
            </p>
        
        </div>
        }


        </>
    );
};

export default BlogGridContainer;