import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import {Link,useParams,useLocation} from 'react-router-dom'

const BlogCard = ({ data }) => {
    const { img, authorImg, author, date, tag ,ContentTitle,ContentDescr,CNTNCRPTID,SearchField } = data;

    useEffect(()=>{
        console.log({data})
    },[])

    return (
        <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="news-block">
                <div className="inner-box">
                    <div className="image">
                        <Link to={`/item/${CNTNCRPTID}`}><img src={img} alt="img" height="400"/></Link>
                    </div>
                    <div className="lower-content">
                        {/* <div className="post-date">{date}</div> */}
                        {/* <div className="author-box">
                            <div className="content">
                                <div className="author-image">
                                    <img src={authorImg} alt="img" />
                                </div>
                                <strong>{author}</strong>
                                <span className="designation">{tag}</span>
                            </div>
                        </div> */}
                        <div className="lower-box">
                            {SearchField &&
                           <> Η λέξη κλειδί βρέθηκε στο πεδίο { SearchField.length>0 ? SearchField : '' }</>
                            }
                            <h5><Link to={`/item/${CNTNCRPTID}`}>{ContentTitle}</Link></h5>
                            {/* <div className="text">We consult our clients to strengthen their decision making for marketing...</div> */}
                            <Link to={`/item/${CNTNCRPTID}`} className="read-more">Περισσότερα</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;