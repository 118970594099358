import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import {Link,useParams,useLocation} from 'react-router-dom'

import BlogDetailContainer from '../../containers/BlogContainers/BlogDetailContainer';

const BlogDetailPage = () => {
    const [itemID,setItemID]=useState('')

    const {itemid}=useParams()

    useEffect(()=>{
        console.log(itemid)
        setItemID(itemid)
    },[itemid])

    return (
        <div className='page-wrapper mt-3'>
            {/* <PageHeader title='Blog' active='BLOG DETAILS' route02='BLOG' /> */}
            <BlogDetailContainer itemid={itemid}/>
        </div>
    );
};

export default BlogDetailPage;