import { RouterProvider,HashRouter,Route,Routes } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { routes } from './Routes/Routes';
import './assets/css/main.css';
import BlogPage02 from './Pages/Blogs/BlogPage02';


function App() {
  // const pathPattern = usePathPattern(); 

  return (
    <div >
      <RouterProvider router={routes} />
    </div>
    // <HashRouter basename={'/'} >
    //   <Routes>
    //     <Route exact path="/" element={BlogPage02}/>
    //     <Route exact path="/category/:catid" element={BlogPage02}/>
    //   </Routes>
    // </HashRouter>
  );
}

export default App;
