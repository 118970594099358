import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import BlogCard from '../../components/BlogElements/BlogCard';
import axios from 'axios'
import {Link,useParams} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'

const BlogGridContainer = () => {

    const [blogGridData,setBlogGridData ]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const {searchStr,setSearchStr}=useContext(UserContext)


    const {catid}=useParams()



    useEffect(()=>{
        window.scrollTo(0, 0)
            loadData()

        
    },[catid])   

    const loadData=()=>{
        setIsLoading(true)
        if (catid){
            var url=process.env.REACT_APP_API_SERVER+'content/category/'+catid
        }else{
            var url=process.env.REACT_APP_API_SERVER+'content'
        }
        //console.log(url)
        axios.get(url)
        .then(res=>{
            //console.log(res.data.results) 
            setBlogGridData(res.data.results)
            setIsLoading(false)
          
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
        }) 
    }


    const blogGridDataList=blogGridData.map(item=>{
        const data={
                id: item.ContentID,
                img: `${process.env.REACT_APP_API_SERVER}files/file/${item.FileID}`,
                authorImg: 'images/blog/author-5.jpg',
                tag: 'Consultant',
                date: 'January 11, 2023',
                author: 'Jessica Ballinger',
                ContentTitle:item.ContentLangTitle,
                ContentDescr:item.ContentDescr,
                CNTNCRPTID:item.CNTNCRPTID
            }

           // console.log(data)
            return(
                <BlogCard
                key={data.id}
                data={data}
                />  
            )

    })

    return (
        <>
        {!isLoading && 
        <section className="news-section">

            <div className="container">
            {catid && blogGridData[0] ? <p ><h2>{blogGridData[0].CategoriesLangTitle}</h2></p> : ''}
                <div className="row">
                    {blogGridDataList}
                </div>
            </div>

        </section>
        }

        {isLoading &&
        <div style={{height:'100vh'}}>
            <p align="center">
            <h2>Loading, please wait...</h2>
            </p>
        
        </div>
        }


        </>
    );
};

export default BlogGridContainer;