import React, { useState,useEffect ,useContext,useRef} from 'react';
import AsideInfoBar from '../common/AsideInfoBar';
import MobileNav from '../common/MobileNav/MobileNav';
import axios from 'axios'
import {UserContext} from '../../context/UserContext'
import { Link,useNavigate } from "react-router-dom"

const ConsultancyHeader = () => {
    const [categories,setCategories]=useState([])
    const {searchStr,setSearchStr}=useContext(UserContext)
    const ss=useRef()
    const navigate=useNavigate()

    useEffect(()=>{
        loadData()
    },[])   

    const loadData=()=>{
        //setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'categories')
        .then(res=>{
            //console.log(res.data.results) 
            setCategories(res.data.results)
            // setIsLoading(false)
          
        }).catch(err=>{
 //           setIsLoading(false)
            console.log(err)
        }) 
    }


    const categoriesList=categories.map(item=>{
        //console.log(item)
        return(
            // <li key={item.NCRPTID}><a href={`/#/category/${item.NCRPTID}`}>{item.CategoriesLangTitle}</a></li>
            <li key={item.NCRPTID}><Link to={`/category/${item.NCRPTID}`}>{item.CategoriesLangTitle}</Link></li>
        )
    })

    const ConsultancyAsideData =
    {
        logo: 'images/logo.svg',
        href: '/consultancy'
    };


    // Sticky Header
    window.onscroll = () => {
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
            document.querySelector('header').classList.add('sticky');
        } else {
            document.querySelector('header').classList.remove('sticky');
        }
    }

    // Aside info bar
    const [isAdd, setAdd] = useState("false");
    const handleAddCss = () => {
        setAdd(!isAdd);
    };

    // Search width increase
    const [isActive, setActive] = useState('true');
    const handleToggle = () => {
        setActive(!isActive);
    };



    const search=(e)=>{
        e.preventDefault()
        sessionStorage.setItem('search',ss.current.value.trim())
       // setSearchStr(ss.current.value.trim())
        navigate('/results#'+Date.now())

    }

    return (
        <>
            <header className="main-header two">

   

                {/* <!-- Header Lower --> */}
                <div className="header-lower">

                    <div className="container">
                        <div className="inner-container d-flex justify-content-between align-items-center">

                            <div className="logo-box">
                                {/* <!-- Logo --> */}
                                <div className="logo"><a href="/"><img src="/images/logo-archives.png" alt="img" title="" /></a></div>
                            </div>
                            <div className="nav-outer d-flex align-items-center">

                                {/* <!-- Main Menu --> */}
                                <nav className="main-menu d-none d-lg-block">
                                    <div className="navbar-collapse collapse clearfix" >
                                        <ul className="navigation clearfix">
                                            <li><a href="/">Αρχική</a></li>

                                            <li><a href="#about">Σχετικά</a>

                                            </li>

                                            <li className="dropdown"><a href="#new">Ψηφιακό Αρχείο</a>
                                                <ul>
                                                    {categoriesList}
                                                </ul>
                                            </li>
                                            <li><a href="#contact">Επικοινωνία</a></li>
                                        </ul>
                                    </div>

                                </nav>
                                {/* <!-- Main Menu End--> */}

                                {/* <!-- Outer Box --> */}
                                <div className="outer-box d-flex align-items-center">

                                    {/* <!-- Header Search --> */}
                                    <div onClick={handleToggle} className="header_search d-none d-sm-block">
                                        <form className={isActive ? null : "active"} id="search_form" onSubmit={search}>
                                            <input ref={ss} value={searchStr} onChange={(e)=>setSearchStr(e.target.value)} type="text" name="search" className="keyword form-control" placeholder="Search..." />
                                            <button type="submit" className="form-control-submit"><img src="/images/icons/search.png" alt="img" /></button>
                                        </form>
                                    </div>

                                    {/* <!-- Aside Panel --> */}
                                    {/* <a onClick={handleAddCss} href="#" className="aside_open d-none d-sm-block"><img src="images/icons/menu.png" alt="img" /></a> */}

                                    {/* <!-- Responsive Menu --> */}
                                    <button className="mobileMenu-toggle d-lg-none d-block ms-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasHome" aria-controls="offcanvasHome"
                                    >
                                        <i className="bi bi-list"></i>
                                    </button>
                                </div>
                                {/* <!-- End Outer Box --> */}

                            </div>

                        </div>

                    </div>
                </div>
                {/* <!-- End Header Lower --> */}

            </header>

            {/* <AsideInfoBar handleAddCss={handleAddCss} isAdd={isAdd} data={ConsultancyAsideData} /> */}
            <MobileNav />
        </ >
    );
};

export default ConsultancyHeader;