import React, { useState,useEffect ,useContext,useRef} from 'react';

import axios from 'axios'
import {UserContext} from '../../context/UserContext'
import { Link } from "react-router-dom"

const SliderCard = ({ data }) => {


    const { mid, heading, tag, bg, title, last } = data;

    return (
        <div className='slide' style={{ backgroundImage: `url(${bg})` }}>
            <div className="container">
                <div className="row clearfix">
                    {/* <!-- Content Column --> */}
                    <div className="content-column col-xl-7 col-lg-7 col-md-10 col-sm-12">
                        <div className="inner-column">
                            <div className="title">{title}</div>
                            <h1><font color="white">{heading} </font><br /> {mid} <span>{tag}</span>{last}</h1>
                            <div className="text"><br/></div>
                            <div className="options-box">
                                {/* <!-- Button Box --> */}
                                <div className="button-box d-flex flex-wrap">
                                    <Link to="/category" className="btn">
                                        <span className="btn-wrap">
                                            <span className="text-one">Ψηφιακό Αρχείο</span>
                                            <span className="text-two">Ψηφιακό Αρχείο</span>
                                        </span>
                                    </Link>
                                    {/* <a href="#" className="btn btn-three">
                                        <span className="btn-wrap">
                                            <span className="text-one">Get Service</span>
                                            <span className="text-two">Get Service</span>
                                        </span>
                                    </a> */}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SliderCard;