import React, {useState,useContext,createContext,useEffect,useRef,}  from 'react'
import BlogCard from '../../components/BlogElements/BlogCard';
import axios from 'axios'
import {Link,useParams,useLocation} from 'react-router-dom'
import { Zoom } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css'
import {UserContext} from '../../context/UserContext'
import SideBar from '../../components/BlogElements/SideBar';

const BlogDetailContainer = (props) => {
    const [item,setItem]=useState({})
    const [mainFileID,setMainFileID]=useState(0)
    const [isLoading,setIsLoading]=useState(false)
    const [files,setFiles]=useState([])
    //const {searchStr,setSearchStr}=useContext(UserContext)
    // const {itemid}=useParams()
    // const location = useLocation();
    const itemid=props.itemid

    const zoomOutProperties = {
        duration: 15000,
        transitionDuration: 500,
        infinite: true,
        indicators: true,
        scale: 0.4,
        arrows: true
      };





    // useEffect(()=>{

    //     loadData()
    //     loadFiles()


    // },[])   

    useEffect(()=>{
        window.scrollTo(0, 0)
        loadData()
        loadFiles()


    },[window.location.hash])  


const loadData=()=>{
    setIsLoading(true)

    var url=process.env.REACT_APP_API_SERVER+'content/'+itemid

    //console.log(url)
    axios.get(url)
    .then(res=>{
       //console.log(res.data.results) 
        setItem(res.data.results[0])
        setMainFileID(res.data.results[0].FileID)
        setIsLoading(false)
        //loadFiles()
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    }) 
}



const loadFiles=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'files/content/'+itemid)
    .then(res=>{
        //console.log(res.data) 
        setFiles(res.data)
        setIsLoading(false)
      
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    }) 
}


    return (
        <section className="blog-details" id="dettop">
            <div className="container">
                <div className="row">

                    <div className="col-lg-8 col-md-7">
                        <div className="blog-details-inner">
                            <div className="post-content">
                                <div className="post-header">
                                    {/* <div className="post-date">January 11, 2023</div> */}
                                    <h1 className="post-title">{item.ContentLangTitle}</h1>
                                </div>                                
                                <figure className="block-gallery mb-0">

                                    {files.length>1 &&
                                    <div className="slide-container">
                                        <Zoom {...zoomOutProperties}>
                                        {files.map((file, index)=> (

                                            <img key={index} style={{ width: "100%" }} src={`${process.env.REACT_APP_API_SERVER}files/file/${file.FileID}`}/>


                                        ))} 
                                        </Zoom>
                                    
                                    </div>
                                    }

                                    {files.length==1 &&
                                    <ul className="blocks-gallery-grid">
                                        <li className="blocks-gallery-item mb-0 me-0">
                                            <figure>
                                                <img src={`${process.env.REACT_APP_API_SERVER}files/file/${mainFileID}`} />
                                            </figure>
                                        </li>
                                    </ul>
                                    }
                                </figure>






                                <div className="intro">
                                    <hr/>
                                    <p> 
                                    <div
                                        dangerouslySetInnerHTML={{__html: item.ContentLangDescr}}
                                        />                                    
                                    </p>
                                    <hr/>
                                </div>


                            </div>
                        </div>

                    </div>

                    <SideBar item={item} files={files} setMainFileID={setMainFileID}/>

                </div>
            </div>
        </section>
    );
};

export default BlogDetailContainer;